<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useSlidesStore} from '@/store'
import {Swiper, SwiperSlide} from "swiper/vue";
import SwiperCore, {Navigation, Autoplay, EffectCoverflow} from "swiper";
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-cube";

SwiperCore.use([Navigation, Autoplay, EffectCoverflow]);
const nextEl = ref<HTMLElement | null>(null);
const prevEl = ref<HTMLElement | null>(null);
const navigation = computed(() => {
  return {
    nextEl: nextEl.value,
    prevEl: prevEl.value,
  }
});
const {
  kitchenSlides
} = storeToRefs(useSlidesStore());
definePageMeta({
  type: 'page-gallery'
});
useSeoMeta({
  title: "Образ Жизни – ресторан-бар в центре Москвы",
  description: "Тот самый бар, который близок каждому. Атмосферное место для ценителей хорошего вина.",
  ogTitle: "Образ Жизни – ресторан-бар в центре Москвы",
  ogDescription: "Тот самый бар, который близок каждому. Атмосферное место для ценителей хорошего вина.",
  ogImage: "https://btwine.ru/og-image.png"
})
</script>

<template>
  <div class="relative flex flex-col items-start h-full">
    <h1 class="absolute text-[12px] text-transparent">Образ Жизни – ресторан-бар в центре Москвы</h1>
    <div class="swiper-block">
      <div ref="prevEl"
           class="z-[1000] absolute w-[80px] h-[80px] cursor-pointer hidden xl:flex items-center justify-center z-10 left-0 swiper-button-prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" fill="none" viewBox="0 0 19 8">
          <path fill="#f8f8f8"
                d="M18 3.5a.5.5 0 0 1 0 1v-1ZM.646 4.354a.5.5 0 0 1 0-.708L3.828.464a.5.5 0 1 1 .708.708L1.707 4l2.829 2.828a.5.5 0 1 1-.708.708L.646 4.354ZM18 4.5H1v-1h17v1Z"/>
        </svg>
      </div>
      <div ref="nextEl"
           class="z-[1000] absolute w-[80px] h-[80px] cursor-pointer hidden xl:flex items-center justify-center rounded-full z-10 right-0  swiper-button-next">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" fill="none" viewBox="0 0 19 8">
          <path fill="#f8f8f8"
                d="M1 3.5a.5.5 0 0 0 0 1v-1Zm17.354.854a.5.5 0 0 0 0-.708L15.172.464a.5.5 0 1 0-.708.708L17.293 4l-2.829 2.828a.5.5 0 1 0 .708.708l3.182-3.182ZM1 4.5h17v-1H1v1Z"/>
        </svg>
      </div>
      <swiper
          :effect="'coverflow'"
          :grabCursor="true"
          :centeredSlides="true"
          :slidesPerView="1"
          :loop="true"
          :navigation="navigation"
          :speed="1000"
          :watchOverflow="true"
          :autoplay="{ delay: 5000, disableOnInteraction: false }"
          :coverflowEffect="{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }"
      >
        <template v-if="kitchenSlides">
          <swiper-slide
              v-for="(item, index) in kitchenSlides"
              :key="index"
          >
            <nuxt-img
                v-if="item?.url"
                format="webp"
                :src="item.thumbnails.large.url"
                sizes="sm:100vw lg:50vw lg:1024px"
                :width="item.thumbnails.large.width"
                :height="item.thumbnails.large.height"
                preload
            />
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.swiper-block {
  @apply fixed top-0 left-0 right-0 bottom-0 w-full h-full p-0 flex items-center;
  @screen lg {
    @apply relative w-full h-auto my-auto;
  }
}

.swiper {
  @apply w-full xl:h-full landscape:scale-[0.6] lg:landscape:scale-[0.9]  xl:landscape:scale-[1];
}

.swiper-slide {
  @apply bg-cover xl:rounded-[4px] overflow-hidden;
  background-position: center;
  display: flex;
  align-items: center;
}

.swiper-slide img {
  @apply h-auto w-full;
}
</style>